import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const PrivacyNotice = () => {
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='mx-3 sm:mx-auto my-6 sm:max-w-[600px] md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-2xl 2xl:px-40'>
            <h2 className='text-4xl font-bold my-3 text-brandText'>{t('policy:privacy_policy_title')}</h2>
            <h2 className='text-2xl font-semibold mt-7 text-brandSecondaryTextColor'>{t('policy:privacy_notice')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:privacy_policy_dec')}
            </p>
            <p className='mt-6 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:thbs_platform')}</p>

            <h2 className='text-2xl font-semibold mt-7 text-brandSecondaryTextColor'>{t('policy:data_collection')}</h2>
            <p className='mt-6 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:data_collection_desc')}
            </p>
            <h2 className='text-2xl font-semibold my-3 text-brandSecondaryTextColor'>{t('policy:visit_website')}</h2>
            <p className='mt-6 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t(`policy:thmp_automatically`)}
            </p>
            <p className='mt-6 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:by_using_thmp_website')}
            </p>
            <p className='mt-6 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:may_contain')}</p>

            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>{t('policy:engage')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:you_can_engage')}</p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>
                {t('policy:access_our_website')}
            </h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:if_you_access_our')}</p>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:when_you_provide')}</p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>{t('policy:purpose_for_use')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:personal_information')}
            </p>
            <div>
                <ul className='pl-8 list-disc'>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:provide_info')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:assess_queries')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:perform_client')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:conduct_data')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:share_it_with')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:share_it_with_statutory')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:market_product')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:conduct_processing')}
                    </li>
                </ul>
            </div>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:with_your_consent')}</p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>{t('policy:disclosure')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:does_not_share')}</p>
            <div>
                <ul className='pl-8 list-disc'>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:we_provide_info')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:we_provide_info')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:shall_share_personal')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:we_believe')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:we_believe_necessary')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:we_may_share')}
                    </li>
                </ul>
            </div>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:how_to_access')}</p>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:you_can_also_make')}</p>
            <div>
                <ul className='pl-8 list-disc'>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:control_the_use')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:choose_whether_you')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:access_and_clear')}
                    </li>
                </ul>
            </div>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:all_personal_data')}</p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>{t('policy:data_transfer')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:may_transfer_certain_personal')}
            </p>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:when_you_provide_us')}
            </p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>{t('policy:preferences')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:takes_reasonable_steps')}
            </p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>{t('policy:profile')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:profile_desc')}</p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>{t('policy:cookies')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:cookies_desc')}</p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>{t('contactUs:contact_us')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:contact_us_desc')}</p>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:we_are_not_able_provide')}
            </p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>{t('policy:data_protection')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:data_protection_one')}
            </p>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:data_protection_two')}
            </p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'> {t('policy:data_retention')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:data_retention_desc')}
            </p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>
                {t('policy:exercise_your_rights')}
            </h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:exercise_your_rights_desc')}
            </p>
            <div>
                <ul className='pl-8 list-disc'>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:right_to_know')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:will_provide_you_with_copy')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:personal_information_incorrect')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:have_the_right')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:you_can_also_ask')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:you_have_the_right')}
                    </li>
                    <li className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                        {t('policy:right_to_object')}
                    </li>
                </ul>
            </div>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>{t('policy:grievances')}</h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:any_ques')}</p>
            <h3 className='text-xl mt-5 font-semibold text-brandSecondaryTextColor'>{t('policy:thbs_llc')}</h3>
            <p className='mt-2 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:usa_address')}</p>
            <p className='mt-6 font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:contact_details')}</p>
            <h3 className='text-xl font-semibold text-brandSecondaryTextColor'>{t('policy:thbs_llc')}</h3>
            <p className='font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:usa_address')}</p>
            <p className=' font-normal text-[16px] text-brandSecondaryTextColor'>{t('policy:email')}</p>
            <h2 className='text-2xl font-semibold mt-6 text-brandSecondaryTextColor'>
                {t('policy:privacy_statement')}
            </h2>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('policy:privacy_statement_desc')}
            </p>
        </div>
    )
}

export default PrivacyNotice
