import { Outlet } from 'react-router-dom'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import '../core-ui/app.css'
import '../core-ui/buttons.css'
import DynamicFooter from '../components/dynamicFooter/DynamicFooter'
import ScrollingComponent from '../ScrollingComponent'
const Root = () => {
    return (
        <div>
            <ScrollingComponent />
            <Header />

            <div className='overflow-x-hidden'>
                <Outlet />
            </div>
            <Footer />
            <DynamicFooter />
        </div>
    )
}

export default Root
