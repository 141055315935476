import React, { useState } from 'react'
import { Typography } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { textile, bbqImg, weddingImg, venture, gymImg, interior, beauty } from '../../constants/media'
import { useTranslation } from 'react-i18next'
import { getImagePath } from '../../util'

const { Title } = Typography

const Carousel = () => {
    const { t } = useTranslation()

    const [currentSlide, setCurrentSlide] = useState(0)

    const handleNext = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length)
    }

    const handlePrev = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length)
    }
    const slides = [
        {
            image: getImagePath('textile'),
            title: ` ${t('home:textile_title')}`,
            challenge: `${t('home:textile_desc')}`,
            solution: `${t('home:textile_solution')}`,
            outcome: `${t('home:textile_outcome')}`,
        },
        {
            image: getImagePath('boutiquehotel'),
            title: ` ${t('home:boutique_title')}`,
            challenge: `${t('home:boutique_desc')}`,
            solution: `${t('home:boutique_solution')}`,
            outcome: `${t('home:boutique_outcome')}`,
        },
        {
            image: getImagePath('wedding'),
            title: ` ${t('home:wedding_title')}`,
            challenge: `${t('home:wedding_desc')}`,
            solution: `${t('home:wedding_solution')}`,
            outcome: `${t('home:wedding_outcome')}`,
        },
        {
            image: getImagePath('interior'),
            title: ` ${t('home:interior_title')}`,
            challenge: `${t('home:interior_desc')}`,
            solution: `${t('home:interior_solution')}`,
            outcome: `${t('home:interior_outcome')}`,
        },
        {
            image: getImagePath('venture'),
            title: ` ${t('home:ventures_title')}`,
            challenge: `${t('home:ventures_desc')}`,
            solution: `${t('home:ventures_solution')}`,
            outcome: `${t('home:ventures_outcome')}`,
        },
        {
            image: getImagePath('fitness'),
            title: ` ${t('home:fitness_title')}`,
            challenge: `${t('home:fitness_desc')}`,
            solution: `${t('home:fitness_solution')}`,
            outcome: `${t('home:fitness_outcome')}`,
        },
        {
            image: getImagePath('beauty'),
            title: ` ${t('home:beauty_title')}`,
            challenge: `${t('home:beauty_desc')}`,
            solution: `${t('home:beauty_solution')}`,
            outcome: `${t('home:beauty_outcome')}`,
        },
    ]

    const { image, title, challenge, solution, outcome } = slides[currentSlide]

    return (
        <section className=''>
            <div className='flex flex-col items-center justify-center my-10 text-center md:flex-row md:text-left'>
                <Title className='font-bold text-[40px] text-brandText break-words'>
                    {t('home:business_benefit')}
                    <br />
                    <Title className='ml-0 md:ml-20'>{t('home:from_digital')}</Title>
                </Title>
            </div>
            <div className='flex flex-col items-center mt-8 mx-6 my-16 md:flex-row md:mx-16 lg:items-start'>
                <div className='w-full mx-4 md:w-1/2'>
                    <img src={image} alt={title} className='w-full h-auto md:w-[520px] md:h-[553px]' />
                </div>
                <div className='w-full mt-4 md:w-1/2 md:text-left lg:text-left md:!mt-0 lg:!mt-0'>
                    <Title level={2} className='!mt-0 font-semibold text-[24px] text-brandText'>
                        {title}
                    </Title>
                    <p>
                        <span className='text-brandSecondaryTextColor font-bold text-[16px]'>
                            {t('home:challenge')}:
                        </span>
                        <br />
                        <span className='text-brandSecondaryTextColor text-[14px]'>{challenge}</span>
                    </p>
                    <p className='mt-4'>
                        <span className='text-brandSecondaryTextColor font-bold text-[16px]'>
                            {t('home:solution')}:
                        </span>{' '}
                        <br />
                        <span className='text-brandSecondaryTextColor text-[14px]'>{solution}</span>
                    </p>
                    <p className='mt-4'>
                        <span className='text-brandSecondaryTextColor font-bold text-[16px]'>{t('home:outcome')}:</span>
                        <br />
                        <span className='text-brandSecondaryTextColor text-[14px]'>{outcome}</span>
                    </p>
                    <div className='flex justify-start mt-4 md:justify-start'>
                        <LeftOutlined onClick={handlePrev} className='cursor-pointer border rounded-md p-2' />
                        <RightOutlined onClick={handleNext} className='cursor-pointer ml-3 border rounded-md p-2' />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Carousel
