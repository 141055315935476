import React, { useEffect } from 'react'
import ContactUsForm from './ContactUsForm'
import { useTranslation } from 'react-i18next'
import { map, plane } from '../../constants/media'

const ContactUs = () => {
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='flex flex-col items-center mx-auto w-full'>
            <div className='flex flex-col items-center my-9 gap-6'>
                <h1 className='text-brandText text-5xl font-bold'>{t('contactUs:lets_connect')}</h1>
                <p>{t('contactUs:lets_connect_description')}</p>
            </div>

            <div className='flex flex-col mb-20 md:flex-row gap-0 md:gap-24 lg:gap-32 md:max-w-6xl'>
                <div className='md:w-2/3'>
                    <ContactUsForm />
                </div>
                <div className='md:w-1/3 p-6 flex flex-col gap-6'>
                    <h2 className='text-5xl font-bold mb-4 text-brandText'>{t('contactUs:contact_us')}</h2>
                    <div className='flex items-start gap-3'>
                        <img src={plane} alt='paper plane' />
                        <p className='text-brandText text-base'>info@torryharrismarketplace.com</p>
                    </div>
                    <div className='flex items-start gap-3'>
                        <img src={map} alt='map' />
                        <p className='text-brandText text-base'>{t('contactUs:torry_harris_address')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
