import React from 'react'
import { footerthbslogo } from '../../constants/media'
import { Divider, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import XingOutlined from '../../assets/svgIcons/XingOutlined'
import ThreadOutlined from '../../assets/svgIcons/ThreadOutlined'
import PintrestOutlined from '../../assets/svgIcons/PintrestOutlined'
import TwitterOutlined from '../../assets/svgIcons/TwitterOutlined'
import InstagramOutlined from '../../assets/svgIcons/InstagramOutlined'
import YoutubeOutlined from '../../assets/svgIcons/YoutubeOutlined'
import FacebookFilled from '../../assets/svgIcons/FacebookFilled'
import LinkedinFilled from '../../assets/svgIcons/LinkedinFilled'

const { Text } = Typography

const Footer = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <section className='!bg-brandBlack py-10 px-4 md:px-16 animate-fadeIn delay-300'>
            <div className='mx-3 sm:mx-auto sm:max-w-[600px] md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-2xl 2xl:px-40'>
                <img src={footerthbslogo} alt='Footer Logo' />
                <div className='flex flex-col gap-3 md:gap-6 space-y-6 md:flex-row mt-6  md:space-y-0'>
                    <p className='text-brandWhite text-xs'>{t('footer:footer_text')}</p>
                    <div className='flex flex-col space-y-3'>
                        <Text
                            className='whitespace-nowrap text-brandWhite hover:text-primaryColor cursor-pointer text-xs'
                            onClick={() => navigate('privacy-notice')}>
                            {t('footer:privacy_notice')}
                        </Text>
                        <Text
                            className='whitespace-nowrap text-brandWhite hover:text-primaryColor cursor-pointer text-xs'
                            onClick={() => navigate('terms-of-use')}>
                            {t('footer:terms_of_service')}
                        </Text>
                        <Text
                            className='whitespace-nowrap text-brandWhite cursor-pointer hover:text-primaryColor text-xs'
                            onClick={() => navigate('cookie-policy')}>
                            {t('footer:cookies_notice')}
                        </Text>
                        <Text
                            className='whitespace-nowrap text-brandWhite cursor-pointer hover:text-primaryColor text-xs'
                            onClick={() => navigate('cancellation-and-refund-policy')}>
                            {t('footer:cancellation_and_refund_policy')}
                        </Text>
                    </div>
                </div>

                <Divider className='bg-brandWhite mt-6' />
                <div className='flex items-center justify-center space-x-4 lg:space-x-9'>
                    <a href='https://www.linkedin.com/company/torry-harris-digital-products/' className='svgIcon'>
                        <LinkedinFilled />
                    </a>
                    <a href='https://www.facebook.com/torryharrisdigitalproducts' className='svgIcon'>
                        <FacebookFilled />
                    </a>
                    <a href='https://www.youtube.com/@TorryHarrisDigitalProducts' className='svgIcon'>
                        <YoutubeOutlined />
                    </a>
                    <a href='https://www.instagram.com/torryharrisdigitalproducts/' className='svgIcon'>
                        <InstagramOutlined />
                    </a>
                    <a href='https://twitter.com/torryharrisdp' className='svgIcon'>
                        <TwitterOutlined />
                    </a>
                    <a href='https://in.pinterest.com/torryharrisdp/' className='svgIcon'>
                        <PintrestOutlined />
                    </a>
                    <a href='https://www.threads.net/@torryharrisdigitalproducts' className='svgIcon'>
                        <ThreadOutlined />
                    </a>
                    <a href='https://www.xing.com/pages/torry-harris-digital-products' className='svgIcon'>
                        <XingOutlined />
                    </a>
                </div>

                <p className='text-xs font-normal text-center mt-8 text-white mx-auto'>{t('footer:copyright')}</p>
            </div>
        </section>
    )
}
export default Footer
