const InstagramOutlined = () => {
    return (
        <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M6.18623 0.0681152H14.5862C17.7862 0.0681152 20.3862 2.66812 20.3862 5.86812V14.2681C20.3862 15.8064 19.7752 17.2816 18.6875 18.3693C17.5997 19.457 16.1245 20.0681 14.5862 20.0681H6.18623C2.98623 20.0681 0.38623 17.4681 0.38623 14.2681V5.86812C0.38623 4.32986 0.9973 2.85461 2.08501 1.7669C3.17272 0.679185 4.64797 0.0681152 6.18623 0.0681152ZM5.98623 2.06812C5.03145 2.06812 4.11578 2.4474 3.44065 3.12253C2.76552 3.79766 2.38623 4.71334 2.38623 5.66812V14.4681C2.38623 16.4581 3.99623 18.0681 5.98623 18.0681H14.7862C15.741 18.0681 16.6567 17.6888 17.3318 17.0137C18.0069 16.3386 18.3862 15.4229 18.3862 14.4681V5.66812C18.3862 3.67812 16.7762 2.06812 14.7862 2.06812H5.98623ZM15.6362 3.56812C15.9678 3.56812 16.2857 3.69981 16.5201 3.93423C16.7545 4.16865 16.8862 4.48659 16.8862 4.81812C16.8862 5.14964 16.7545 5.46758 16.5201 5.702C16.2857 5.93642 15.9678 6.06812 15.6362 6.06812C15.3047 6.06812 14.9868 5.93642 14.7523 5.702C14.5179 5.46758 14.3862 5.14964 14.3862 4.81812C14.3862 4.48659 14.5179 4.16865 14.7523 3.93423C14.9868 3.69981 15.3047 3.56812 15.6362 3.56812ZM10.3862 5.06812C11.7123 5.06812 12.9841 5.5949 13.9218 6.53258C14.8594 7.47026 15.3862 8.74203 15.3862 10.0681C15.3862 11.3942 14.8594 12.666 13.9218 13.6036C12.9841 14.5413 11.7123 15.0681 10.3862 15.0681C9.06015 15.0681 7.78838 14.5413 6.8507 13.6036C5.91301 12.666 5.38623 11.3942 5.38623 10.0681C5.38623 8.74203 5.91301 7.47026 6.8507 6.53258C7.78838 5.5949 9.06015 5.06812 10.3862 5.06812ZM10.3862 7.06812C9.59058 7.06812 8.82752 7.38419 8.26491 7.94679C7.7023 8.5094 7.38623 9.27247 7.38623 10.0681C7.38623 10.8638 7.7023 11.6268 8.26491 12.1894C8.82752 12.752 9.59058 13.0681 10.3862 13.0681C11.1819 13.0681 11.9449 12.752 12.5076 12.1894C13.0702 11.6268 13.3862 10.8638 13.3862 10.0681C13.3862 9.27247 13.0702 8.5094 12.5076 7.94679C11.9449 7.38419 11.1819 7.06812 10.3862 7.06812Z'
                fill='white'
            />
        </svg>
    )
}

export default InstagramOutlined
