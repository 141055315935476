const FacebookFilled = () => {
    return (
        <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_320_4392)'>
                <path
                    d='M13.7176 7.32437H12.1426H11.5801V6.76187V5.01812V4.45562H12.1426H13.3238C13.6332 4.45562 13.8863 4.23062 13.8863 3.89312V0.911865C13.8863 0.60249 13.6613 0.349365 13.3238 0.349365H11.2707C9.04883 0.349365 7.50195 1.92437 7.50195 4.25874V6.70562V7.26812H6.93945H5.02695C4.6332 7.26812 4.26758 7.57749 4.26758 8.02749V10.0525C4.26758 10.4462 4.57695 10.8119 5.02695 10.8119H6.8832H7.4457V11.3744V17.0275C7.4457 17.4212 7.75508 17.7869 8.20508 17.7869H10.8488C11.0176 17.7869 11.1582 17.7025 11.2707 17.59C11.3832 17.4775 11.4676 17.2806 11.4676 17.1119V11.4025V10.84H12.0582H13.3238C13.6895 10.84 13.9707 10.615 14.027 10.2775V10.2494V10.2212L14.4207 8.28062C14.4488 8.08374 14.4207 7.85874 14.252 7.63374C14.1957 7.49311 13.9426 7.35249 13.7176 7.32437Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_320_4392'>
                    <rect width='18' height='18' fill='white' transform='translate(0.38623 0.0681152)' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FacebookFilled
