import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const TermsOfUse = () => {
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='mx-3 sm:mx-auto my-6 sm:max-w-[600px] md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-2xl 2xl:px-40'>
            <h2 className='text-4xl font-bold my-3 text-brandText'>{t('termsOfUse:terms_of_use')}</h2>
            <p className='mt-7 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('termsOfUse:terms_of_use_desc')}
            </p>
            <div className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                <ul className='pl-8 list-disc'>
                    <li>{t('termsOfUse:web_site_may')}</li>
                    <li>{t('termsOfUse:web_site_may_include')}</li>
                    <li>{t('termsOfUse:you_are_granted')}</li>
                    <li>{t('termsOfUse:wherever_web_site')}</li>
                    <li>{t('termsOfUse:while_using_third')}</li>
                    <li>{t('termsOfUse:the_info_material')}</li>
                    <li>{t('termsOfUse:agree_to_protect')}</li>
                    <li>{t('termsOfUse:no_event')}</li>
                    <li>{t('termsOfUse:thbs_platform_warrent')}</li>
                    <li>{t('termsOfUse:thbs_platform_reserves')}</li>
                    <li>{t('termsOfUse:you_agree_to_indemnify')}</li>
                    <li>{t('termsOfUse:sole_discretion')}</li>
                    <li>{t('termsOfUse:each_party_entitled')}</li>
                    <li>{t('termsOfUse:thbs_representation')}</li>
                    <li>{t('termsOfUse:the_security_of_information')}</li>
                    <li>{t('termsOfUse:you_will_not_exploit')}</li>
                    <li>{t('termsOfUse:may_cancel_your_acc')}</li>
                    <li>{t('termsOfUse:without_limiting')}</li>
                    <li>{t('termsOfUse:data_uploaded_or_generated')}</li>
                    <li>
                        {t('termsOfUse:termination_of_the_services')}
                        <ul className='pl-8 list-disc'>
                            <li>{t('termsOfUse:thbp_providing')}</li>
                            <li>{t('termsOfUse:unless_provided')}</li>
                            <li>{t('termsOfUse:thmp_shop')}</li>
                            <li>{t('termsOfUse:thmp_not_responsible')}</li>
                            <li>{t('termsOfUse:no_longer_access')}</li>
                            <li>{t('termsOfUse:outstanding_balance_owed')} </li>
                        </ul>
                    </li>
                    <li>{t('termsOfUse:without_giving_effect')}</li>
                </ul>
            </div>
        </div>
    )
}

export default TermsOfUse
