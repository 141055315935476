const PintrestOutlined = () => {
    return (
        <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M7.65377 19.6081C8.61377 19.8981 9.58377 20.0681 10.6138 20.0681C13.2659 20.0681 15.8095 19.0145 17.6848 17.1392C19.5602 15.2638 20.6138 12.7203 20.6138 10.0681C20.6138 8.7549 20.3551 7.45454 19.8526 6.24128C19.35 5.02802 18.6134 3.92563 17.6848 2.99705C16.7563 2.06846 15.6539 1.33187 14.4406 0.82932C13.2273 0.326773 11.927 0.0681152 10.6138 0.0681152C9.30055 0.0681152 8.00019 0.326773 6.78693 0.82932C5.57368 1.33187 4.47129 2.06846 3.5427 2.99705C1.66734 4.87241 0.61377 7.41595 0.61377 10.0681C0.61377 14.3181 3.28377 17.9681 7.05377 19.4081C6.96377 18.6281 6.87377 17.3381 7.05377 16.4481L8.20377 11.5081C8.20377 11.5081 7.91377 10.9281 7.91377 10.0081C7.91377 8.62811 8.77377 7.59811 9.75377 7.59811C10.6138 7.59811 11.0138 8.22812 11.0138 9.03812C11.0138 9.89812 10.4438 11.1281 10.1538 12.3081C9.98377 13.2881 10.6738 14.1481 11.6738 14.1481C13.4538 14.1481 14.8338 12.2481 14.8338 9.56812C14.8338 7.16812 13.1138 5.52812 10.6438 5.52812C7.82377 5.52812 6.16377 7.62812 6.16377 9.83812C6.16377 10.6981 6.44377 11.5681 6.90377 12.1381C6.99377 12.1981 6.99377 12.2781 6.96377 12.4281L6.67377 13.5181C6.67377 13.6881 6.56377 13.7481 6.39377 13.6281C5.11377 13.0681 4.37377 11.2481 4.37377 9.77812C4.37377 6.61812 6.61377 3.74812 10.9338 3.74812C14.3738 3.74812 17.0538 6.21812 17.0538 9.49812C17.0538 12.9381 14.9238 15.6981 11.8738 15.6981C10.9038 15.6981 9.95377 15.1781 9.61377 14.5681L8.94377 16.9381C8.71377 17.7981 8.08377 18.9481 7.65377 19.6381V19.6081Z'
                fill='white'
            />
        </svg>
    )
}

export default PintrestOutlined
