import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const DynamicFooter = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [showBanner, setShowBanner] = useState(true)

    const handleConsent = () => {
        setShowBanner(false)
    }

    if (!showBanner) return null

    return (
        <div className='sticky bottom-0 w-full bg-footerColor text-white text-xs z-50 py-2 flex justify-evenly items-center'>
            <div>
                {t('footer:dynamic_footer_text')}
                <p
                    onClick={() => {
                        navigate('/privacy-notice')
                    }}
                    className='underline cursor-pointer inline-block'>
                    {t('common:privacy_policy')}
                </p>{' '}
                &{' '}
                <p
                    onClick={() => {
                        navigate('/cookie-policy')
                    }}
                    className='underline cursor-pointer inline-block'>
                    {t('common:cookie_policy')}
                </p>
                .
            </div>
            <button onClick={handleConsent} className='text-end text-white'>
                X
            </button>
        </div>
    )
}

export default DynamicFooter
