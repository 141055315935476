import React from 'react'
import { Typography, Button } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { success } from '../../constants/media'

const { Title, Text } = Typography
const subscriptionAmount = process.env.REACT_APP_SUBSCRIPTION_AMOUNT
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL
const signUpLink = process.env.REACT_APP_SIGN_UP

const CommunitySection = () => {
    const { t } = useTranslation()

    const handleExternalLinkClick = () => {
        window.open(signUpLink, '_blank')
    }

    return (
        <section className='bg-brandBlack text-white lg:p-16 p-7 flex flex-col md:flex-row justify-center items-center'>
            <div className='w-full md:w-1/2 md:pr-16'>
                <Title className='!text-brandWhite text-[32px] md:text-[40px] font-bold'>
                    {t('home:start_for')} <span className='text-orange-500'>{t('home:free')}</span>
                </Title>
                <Title className='!text-brandWhite text-[32px] md:text-[40px] font-bold lg:!-mt-[10px] !-mt-[15px]'>
                    {t('home:experience_community')}
                </Title>
                <Text className='text-white text-[18px] mt-6'>{t('home:start_free_trial')}:</Text>
                <div className='mt-6'>
                    <div className='flex items-center mb-4'>
                        <img src={success} className=' mr-2' />
                        <Text className='text-white text-[16px]'>{t('home:product_management')}</Text>
                    </div>
                    <div className='flex items-center mb-4'>
                        <img src={success} className=' mr-2' />
                        <Text className='text-white text-[16px]'>{t('home:responsive_storefront')}</Text>
                    </div>
                    <div className='flex items-center mb-4'>
                        <img src={success} className=' mr-2' />
                        <Text className='text-white text-[16px]'>{t('home:analytics_reports')}</Text>
                    </div>
                </div>
                <button
                    className='app-primary-btn p-3 mt-6 w-full lg:w-auto md:w-auto'
                    onClick={() => {
                        handleExternalLinkClick(signUpLink)
                    }}>
                    {t('common:get_started')}
                </button>
            </div>
            <div className='w-full md:w-1/2 mt-12 md:mt-0 flex justify-center'>
                <div className='border-2 border-primaryColor rounded-[32px] lg:w-[316px] lg:h-[440px] p-8  md:w-3/4'>
                    <Title className='!text-brandWhite text-[24px] font-semibold'>{t('home:community')}</Title>
                    <Text className='!text-brandWhite text-[16px] block mt-4'>{t('home:marketplace_app')}</Text>
                    <Title className='!text-primaryColor text-[48px] font-bold mt-4'>
                        {currencySymbol}
                        {subscriptionAmount}*
                    </Title>
                    <Text className='text-primaryColor text-[12px]'>{t('home:billed_monthly')}</Text>
                    <Text className='text-white text-[14px] flex mt-24'>*{t('home:plus_tax')}</Text>
                </div>
            </div>
        </section>
    )
}

export default CommunitySection
