import { createBrowserRouter } from 'react-router-dom'

import Root from './pages/Root'
import ContactUs from './pages/contactUs/ContactUs'
import LandingPage from './pages/landingPage/LandingPage'
import PrivacyNotice from './pages/Privacy-notice/PrivacyNotice'
import TermsOfUse from './pages/TermsOfUse/TermsOfUse'
import CancellationAndRefundPolicy from './pages/CancellationAndRefundPolicy'
import CookiePolicy from './pages/CookiePolicy/CookiePolicy'

export default createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                index: true,
                element: <LandingPage />,
            },
            {
                path: 'contact-us',
                element: <ContactUs />,
            },
            {
                path: 'privacy-notice',
                element: <PrivacyNotice />,
            },
            {
                path: 'terms-of-use',
                element: <TermsOfUse />,
            },
            {
                path: 'cancellation-and-refund-policy',
                element: <CancellationAndRefundPolicy />,
            },
            {
                path: 'cookie-policy',
                element: <CookiePolicy />,
            },
        ],
    },
])
