import React from 'react'

const XingOutlined = () => {
    return (
        <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M6.07244 6.38144C5.90926 6.38144 5.77193 6.43878 5.70266 6.55092C5.63122 6.66662 5.64203 6.81579 5.71826 6.96675L7.55145 10.1401C7.55455 10.1461 7.55455 10.15 7.55145 10.1555L4.67068 15.2392C4.59558 15.3888 4.59925 15.5391 4.67068 15.6551C4.73986 15.7667 4.86178 15.8399 5.02514 15.8399H7.73634C8.14165 15.8399 8.33725 15.5664 8.4758 15.3163C8.4758 15.3163 11.2931 10.3337 11.4028 10.1402C11.3917 10.1224 9.53889 6.88986 9.53889 6.88986C9.40363 6.64952 9.19994 6.38135 8.7841 6.38135H6.07244V6.38144Z'
                fill='white'
            />
            <path
                d='M17.4619 2.66874C17.0569 2.66874 16.8814 2.92384 16.736 3.18515C16.736 3.18515 10.8951 13.5434 10.7026 13.8835C10.7125 13.9019 14.5549 20.9511 14.5549 20.9511C14.6895 21.1915 14.8972 21.4675 15.3125 21.4675H18.0205C18.1839 21.4675 18.3115 21.406 18.3804 21.2943C18.4527 21.1787 18.4507 21.0257 18.3748 20.8752L14.5526 13.8919C14.5508 13.8892 14.5498 13.8861 14.5498 13.883C14.5498 13.8798 14.5508 13.8767 14.5526 13.8741L20.5554 3.26016C20.6309 3.11033 20.6325 2.95749 20.5611 2.84169C20.492 2.73002 20.3639 2.66846 20.2006 2.66846H17.4616V2.66864H17.4619V2.66874Z'
                fill='white'
            />
        </svg>
    )
}

export default XingOutlined
