const TwitterOutlined = () => {
    return (
        <svg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M0.432476 0.0681152L7.85592 9.99604L0.38623 18.0681H2.06785L8.60819 11.0012L13.8921 18.0681H19.6137L11.7729 7.58175L18.726 0.0681152H17.0444L11.0218 6.57655L6.15514 0.0681152H0.433591H0.432476ZM2.90469 1.30685H5.53257L17.1392 16.8294H14.5113L2.90469 1.30685Z'
                fill='white'
            />
        </svg>
    )
}

export default TwitterOutlined
