import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const CookiePolicy = () => {
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const cookiesList = [
        {
            name: ` ${t('cookie:hsid')}`,
            domain: ` ${t('cookie:google')}`,
            description: ` ${t('cookie:hsid_desc')}`,
        },
        {
            name: ` ${t('cookie:ga')}`,
            domain: ` ${t('cookie:google_analytics')}`,
            description: ` ${t('cookie:google_desc')}`,
        },
        {
            name: ` ${t('cookie:twitter_name')}`,
            domain: ` ${t('cookie:twitter')}`,
            description: ` ${t('cookie:twitter_desc')}`,
        },
        {
            name: ` ${t('cookie:utube_name')}`,
            domain: ` ${t('cookie:utube')}`,
            description: ` ${t('cookie:utube_desc')}`,
        },
        {
            name: ` ${t('cookie:jar')}`,
            domain: ` ${t('cookie:gstatic')}`,
            description: ` ${t('cookie:gstatic_desc')}`,
        },
        {
            name: ` ${t('cookie:zync')}`,
            domain: ` ${t('cookie:rezync')}`,
            description: ` ${t('cookie:rezync_desc')}`,
        },
        {
            name: ` ${t('cookie:aws_title')}`,
            domain: ` ${t('cookie:aws')}`,
            description: ` ${t('cookie:aws_desc')}`,
        },
    ]

    return (
        <div className='mx-3 sm:mx-auto my-6 sm:max-w-[600px] md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-2xl 2xl:px-40'>
            <h2 className='text-4xl font-bold my-3 text-brandText'>{t('cookie:cookie_policy')}</h2>
            <p className='mt-7 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cookie:cookie_policy_desc_one')}
            </p>
            <p className='my-6 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cookie:cookie_policy_desc_two')}
            </p>
            <h2 className='text-3xl font-semibold my-3 text-brandSecondaryTextColor'>{t('cookie:cookie_notice')}</h2>
            <p className='mt-7 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cookie:cookie_notice_desc_one')}
            </p>
            <p className='mt-7 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cookie:cookie_notice_desc_two')}
            </p>
            <p className='mt-7 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cookie:cookie_notice_social')}
            </p>
            <p className='mt-7 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cookie:cookie_notice_analytics')}
            </p>
            <p className='mt-7 font-normal text-[16px] text-brandSecondaryTextColor'>{t('cookie:by_using_website')}</p>
            <div className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>
                <ul className='pl-8 list-disc'>
                    <li>{t('cookie:cookie_may_be')}</li>
                    <li>{t('cookie:cookie_can_have')}</li>
                    <li>{t('cookie:cookies_may_be_stored')}</li>
                    <li>{t('cookie:cookies_used_on')}</li>
                </ul>
            </div>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('cookie:as_described')}</p>
            <p className='my-3 font-normal text-[16px] text-brandSecondaryTextColor'>{t('cookie:cookies_list')}</p>
            <table className='min-w-full bg-white border border-gray-300 my-9'>
                <thead>
                    <tr className='!bg-brandSecondaryBgColor'>
                        <th className='py-5 border-r border-b border-gray-300 text-brandText font-medium text-[14px] '>
                            {t('cookie:name')}
                        </th>
                        <th className='py-2  border-r border-b border-gray-300 text-brandText font-medium text-[14px]'>
                            {t('cookie:domain')}
                        </th>
                        <th className='py-2  border-b text-brandText font-medium text-[14px]'>
                            {t('cookie:description')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {cookiesList.map((cookie, index) => (
                        <tr key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-white'}`}>
                            <td className='py-2 px-4 border-r border-b border-gray-300 text-[16px] text-brandSecondaryTextColor'>
                                {cookie.name}
                            </td>
                            <td className='py-2 px-4 border-r border-b border-gray-300 text-[16px] text-brandSecondaryTextColor'>
                                {cookie.domain}
                            </td>
                            <td className='py-2 px-4 border-b text-[16px] text-brandSecondaryTextColor'>
                                {cookie.description}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default CookiePolicy
