const region = process.env.REACT_APP_DEPLOYMENT_REGION.toUpperCase()
export const getImagePath = (image) => {
    switch (region) {
        case 'INDIA':
            return require(`./assets/images/india/${image}.png`)

        case 'UAE':
            return require(`./assets/images/uae/${image}.png`)

        default:
            return require(`./assets/images/common/${image}.png`)
    }
}
