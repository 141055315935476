import React from 'react'
import { useTranslation } from 'react-i18next'
import { success } from '../../constants/media'
import { Typography } from 'antd'
import { getImagePath } from '../../util'

const signUP = process.env.REACT_APP_SIGN_UP
const subscribeLink = process.env.REACT_APP_SUBSCRIPE
const bannerImagePath = getImagePath('banner')

const { Text, Title } = Typography

const Banner = () => {
    const { t } = useTranslation()
    const handleExternalLinkClick = (address) => {
        window.open(address, '_blank')
    }
    return (
        <section className='bg-brandBgColor lg:p-16 p-7 flex flex-col md:flex-row items-center'>
            <div className='w-full md:w-1/2'>
                <Title className='!text-brandBlack text-[30px] lg:!text-[56px] md:!text-[40px] font-bold'>
                    {t('home:fast_track')}
                </Title>
                <Title className='!text-brandBlack transition-opacity !text-[41px] !-mt-[20px] lg:!-mt-[32px] lg:!text-[56px] md:!text-[55px] md:!-mt-[20px] font-bold'>
                    {t('home:business')}
                </Title>
                <Text className='!text-brandBlack transition-opacity !text-[18px] lg:!-mt-[28px] lg:!text-[18px] md:!text-[55px] md:!-mt-[20px] font-medium'>
                    {t('home:sell_better')}
                </Text>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-4 lg:mt-8 mt-4 gap-2'>
                    <div className='flex items-center'>
                        <img src={success} alt='Success' className='w-[1rem] h-[1rem] mr-4' />
                        <Text className='font-normal text-[16px] text-brandBlack'>{t('home:sales_tracker')}</Text>
                    </div>
                    <div className='flex items-center'>
                        <img src={success} alt='Success' className='w-[1rem] h-[1rem] mr-4' />
                        <Text className='font-normal text-[16px] text-brandBlack'>{t('home:cloud_based')}</Text>
                    </div>
                    <div className='flex items-center'>
                        <img src={success} alt='Success' className='w-[1rem] h-[1rem] mr-4' />
                        <Text className='font-normal text-[16px] text-brandBlack'>{t('home:multiple_payment')}</Text>
                    </div>
                    <div className='flex items-center'>
                        <img src={success} alt='Success' className='w-[1rem] h-[1rem] mr-4' />
                        <Text className='font-normal text-[16px] text-brandBlack'>
                            {t('home:intuitive_mobile_app')}
                        </Text>
                    </div>
                </div>
                <div className='mt-5 lg:space-x-3 flex flex-col md:flex-row md:space-x-3'>
                    <button
                        className='app-primary-btn p-3 mb-3 md:mb-0 w-full md:w-auto'
                        onClick={() => {
                            handleExternalLinkClick(signUP)
                        }}>
                        {t('common:get_started')}
                    </button>
                    <button
                        className='app-secondary-btn p-3 w-full md:w-auto'
                        onClick={() => {
                            handleExternalLinkClick(subscribeLink)
                        }}>
                        {t('common:login')}
                    </button>
                </div>

                <div className='mt-1.5'>
                    <Text className='text-primaryColor font-bold text-[16px]'>{t('home:free_trial')}</Text>
                </div>
            </div>
            <div className='w-full md:w-1/2 mt-8 md:mt-0 flex justify-center'>
                <img src={bannerImagePath} alt='India banner' className='w-full  mx-auto' />
            </div>
        </section>
    )
}

export default Banner
