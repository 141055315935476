const LinkedinFilled = () => {
    return (
        <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M16.3862 0.0681152C16.9167 0.0681152 17.4254 0.278829 17.8004 0.653902C18.1755 1.02897 18.3862 1.53768 18.3862 2.06812V16.0681C18.3862 16.5985 18.1755 17.1073 17.8004 17.4823C17.4254 17.8574 16.9167 18.0681 16.3862 18.0681H2.38623C1.8558 18.0681 1.34709 17.8574 0.972017 17.4823C0.596944 17.1073 0.38623 16.5985 0.38623 16.0681V2.06812C0.38623 1.53768 0.596944 1.02897 0.972017 0.653902C1.34709 0.278829 1.8558 0.0681152 2.38623 0.0681152H16.3862ZM15.8862 15.5681V10.2681C15.8862 9.40351 15.5428 8.57432 14.9314 7.96295C14.32 7.35158 13.4908 7.00811 12.6262 7.00811C11.7762 7.00811 10.7862 7.52812 10.3062 8.30812V7.19812H7.51623V15.5681H10.3062V10.6381C10.3062 9.86811 10.9262 9.23812 11.6962 9.23812C12.0675 9.23812 12.4236 9.38561 12.6862 9.64817C12.9487 9.91072 13.0962 10.2668 13.0962 10.6381V15.5681H15.8862ZM4.26623 5.62812C4.71179 5.62812 5.13911 5.45112 5.45417 5.13605C5.76923 4.82099 5.94623 4.39368 5.94623 3.94812C5.94623 3.01812 5.19623 2.25812 4.26623 2.25812C3.81801 2.25812 3.38816 2.43617 3.07122 2.7531C2.75428 3.07004 2.57623 3.4999 2.57623 3.94812C2.57623 4.87812 3.33623 5.62812 4.26623 5.62812ZM5.65623 15.5681V7.19812H2.88623V15.5681H5.65623Z'
                fill='white'
            />
        </svg>
    )
}

export default LinkedinFilled
