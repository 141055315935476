import React from 'react'

//  This is a custom drop down component
//! Attributes
//  children         -> holds the elements to be rendered inside the component
//  items            -> items is a list of elements to be rendered inside the dropdown
// structure of items ->
// [
//     key : key for the element (should be unique)
//     label : element to be rendered in the dropdown
// ]
//  buttonClassName  -> class name for the dropDown button
//  itemsClassName   -> class name for the individual items in the dropdown

const Dropdown = ({ children, items, buttonClassName, itemsClassName }) => {
    return (
        <div className='relative inline-block text-center'>
            <div className='group'>
                <button
                    type='button'
                    className={` w-full ${buttonClassName}`}
                    id='options-menu'
                    aria-expanded='true'
                    aria-haspopup='true'>
                    {children}
                </button>
                <div
                    className={`origin-top absolute left-1/2 transform -translate-x-1/2 mt-1 rounded-md shadow-xl bg-white  opacity-0 group-hover:opacity-100  transition ease-out duration-200`}
                    role='menu'
                    style={{ boxShadow: '0px 4px 16px 8px #0000000D' }}
                    aria-orientation='horizontal'
                    aria-labelledby='options-menu'>
                    <div className='py-2' role='none'>
                        {items.map((element) => (
                            <div
                                key={element.key}
                                className={`hover:bg-[var(--mp-disabled-background-color)] p-1 ${itemsClassName}`}>
                                {element.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dropdown
