import { useMutation } from '@tanstack/react-query'
import api from '../lib/apiClient'

const contactUsEndPoint = process.env.REACT_APP_CONTACT_US_API

const contactUsApiCall = async (data) => {
    const response = await api.post(contactUsEndPoint, data)
    return response.data
}

const useContactUsMutation = () => {
    return useMutation({ mutationFn: contactUsApiCall })
}

export default useContactUsMutation