import { Modal, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { fireWorks, undrawDrawing } from '../../constants/media'
import { useState } from 'react'
import useContactUsMutation from '../../hooks/useContactUsMutation'

const RequestDemoModal = ({ requestDemoModalOpen, setRequestDemoModalOpen }) => {
    const { mutate: contactUsMutate } = useContactUsMutation()
    const { t } = useTranslation()
    const [formData, setFormData] = useState({
        name: '',
        business_email: '',
        product_demo: true,
    })

    const [errors, setErrors] = useState({})
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
    const [requestDemoPostSuccess, setRequestDemoPostSuccess] = useState(false)

    const validateForm = () => {
        const errors = {}
        if (!formData.name) {
            errors.name = t('common:error_message_name')
        }
        if (!formData.business_email) {
            errors.business_email = t('common:error_message_email_empty')
        } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(formData.business_email)) {
            errors.business_email = t('common:error_message_email_wrong')
        }
        return errors
    }

    const handleFormSubmit = (event) => {
        setIsSubmitButtonDisabled(true)
        event.preventDefault()
        const validationErrors = validateForm()
        if (Object.keys(validationErrors).length === 0) {
            console.log('Form submitted', formData)
            contactUsMutate(formData, {
                onSuccess: () => {
                    setFormData({
                        name: '',
                        business_email: '',
                        product_demo: true,
                    })
                    setRequestDemoPostSuccess(true)
                },
                onError: (error) => {
                    notification.error({
                        message: 'Error',
                        description: error.response?.data?.response_body?.message || 'An error occurred',
                        pauseOnHover: true,
                    })
                },
            })
            setErrors({})
        } else {
            setErrors(validationErrors)
        }
        setIsSubmitButtonDisabled(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }))
    }
    return (
        <Modal
            title={
                <p className='text-brandText font-bold text-3xl'>
                    {requestDemoPostSuccess ? t('common:fantastic') : t('common:request_a_demo')}
                </p>
            }
            open={requestDemoModalOpen}
            keyboard
            footer={null}
            destroyOnClose
            centered={true}
            className='min-w-max'
            onCancel={() => {
                setRequestDemoModalOpen(false)
            }}>
            <div className='p-0 md:p-5'>
                {requestDemoPostSuccess ? (
                    <div className='flex flex-col gap-3 '>
                        <img src={fireWorks} alt='fireworks' className='' />
                        <div className='text-brandSecondaryTextColor whitespace-normal'>
                            {t('common:response_message')}
                        </div>
                        <button
                            className='app-primary-btn w-full py-2'
                            onClick={() => {
                                setRequestDemoModalOpen(false)
                            }}>
                            {t('common:close_message')}
                        </button>
                    </div>
                ) : (
                    <div className='flex flex-col md:flex-row-reverse justify-between items-center gap-10'>
                        <div className=''>
                            <img src={undrawDrawing} alt='form' className='aspect-square w-24 md:w-80 ' />
                        </div>
                        <form onSubmit={handleFormSubmit} className='flex-1 flex flex-col gap-6'>
                            <div>
                                <p className='text-brandGray3'>
                                    {t('common:your_name')}
                                    <span className='text-red-500'>*</span>
                                </p>
                                <div>
                                    <input
                                        type='text'
                                        name='name'
                                        placeholder={t('common:name_placeholder')}
                                        className='border border-gray-300 rounded-lg px-4 py-3 text-gray-500 w-full'
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && <p className='text-red-500 text-sm'>{errors.name}</p>}
                                </div>
                            </div>
                            <div>
                                <p className='text-brandGray3'>
                                    {t('common:your_email')}
                                    <span className='text-red-500'>*</span>
                                </p>
                                <div>
                                    <input
                                        type='email'
                                        name='business_email'
                                        placeholder={t('common:email_placeholder')}
                                        className='border border-gray-300 rounded-lg px-4 py-3 text-gray-500 w-full'
                                        value={formData.business_email}
                                        onChange={handleChange}
                                    />
                                    {errors.business_email && <p className='text-red-500 text-sm'>{errors.business_email}</p>}
                                </div>
                            </div>
                            <div className='text-brandSecondaryTextColor'>{t('common:form_consent')}</div>
                            <button className='app-primary-btn p-3' type='submit' disabled={isSubmitButtonDisabled}>
                                {t('common:request')}
                            </button>
                        </form>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default RequestDemoModal
