import React from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { starImg, radarImg, gotrackImg } from '../../constants/media'
import { getImagePath } from '../../util'

const { Text, Title } = Typography
const operationImagePath = getImagePath('operation')

const ThbsOperation = () => {
    const { t } = useTranslation()

    return (
        <section className='!bg-brandSecondaryBgColor flex flex-col md:flex-row'>
            <div className='md:w-1/2 lg:p-16 p-7'>
                <Title className='!text-brandBlack text-[41px] lg:!text-[40px] md:!text-[40px] font-bold'>
                    {t('home:streamline')}
                </Title>
                <p className='font-normal text-[18px] text-brandSecondaryTextColor'>{t('home:Thbs_desc')}</p>
                <div className=' flex flex-col'>
                    <div className='flex mt-10'>
                        <img src={starImg} className='w-[38px] h-[38px]' alt='star' />
                        <Text className='font-semibold text-[24px] text-brandText ml-3'>{t('home:effortless')} :</Text>
                    </div>
                    <p className='pl-12 text-[14px] text-brandSecondaryTextColor'>{t('home:effortless_desc')}</p>
                    <div className='flex mt-10'>
                        <img src={radarImg} className='w-[38px] h-[38px]' alt='star' />
                        <Text className='font-semibold text-[24px] text-brandText ml-3'>{t('home:real_time')} :</Text>
                    </div>
                    <p className='pl-12  text-[14px] text-brandSecondaryTextColor'>{t('home:real_time_desc')}</p>
                    <div className='flex mt-10'>
                        <img src={gotrackImg} className='w-[38px] h-[38px]' alt='star' />
                        <Text className='font-semibold text-[24px] text-brandText ml-3'>
                            {t('home:simplified_text')} :
                        </Text>
                    </div>
                    <p className='pl-12 text-[14px] text-brandSecondaryTextColor'>{t('home:simplified_text_desc')}</p>
                </div>
            </div>
            <div className='md:w-1/2 mb-5 lg:mb-0'>
                <img src={operationImagePath} alt='Operation' className='w-full h-auto' />
            </div>
        </section>
    )
}

export default ThbsOperation
