import React, { useState } from 'react'
import api from '../../lib/apiClient'
import { notification } from 'antd'
import { t } from 'i18next'
import useContactUsMutation from '../../hooks/useContactUsMutation'

const contactUsEndPoint = process.env.REACT_APP_CONTACT_US_API
const PostPutCallURL = process.env.REACT_APP_THDP_URL
const ContactUsForm = () => {
    const { mutate: contactUsMutate } = useContactUsMutation()
    const navigateToExternal = () => {
        window.location.href = PostPutCallURL
    }
    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        business_email: '',
        phone: '',
        message: '',
        product_demo: false,
    })

    const [errors, setErrors] = useState({})

    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)

    const validateForm = () => {
        const errors = {}
        if (!formData.name) {
            errors.name = t('common:error_message_name')
        }
        // if (!formData.companyName) {
        //     errors.companyName = 'Company Name is required'
        // }
        if (!formData.business_email) {
            errors.business_email = t('common:error_message_email_empty')
        } else if (
            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(formData.business_email)
        ) {
            errors.business_email = t('common:error_message_email_wrong')
        }
        // if (formData.phone && !/^\+?[\d\s\-()]{7,15}$/.test(formData.phone)) {
        //     errors.phone = 'Invalid phone number'
        // }

        return errors
    }

    const handleFormSubmit = (event) => {
        setIsSubmitButtonDisabled(true)
        event.preventDefault()
        const validationErrors = validateForm()
        if (Object.keys(validationErrors).length === 0) {
            console.log('Form submitted', formData)
            contactUsMutate(formData, {
                onSuccess: () => {
                    notification.success({
                        message: 'Success',
                        description: 'Thank you! One of our representatives will contact you soon.',
                        pauseOnHover: true,
                    })
                    setTimeout(() => {
                        navigateToExternal()
                    }, 5000)
                },
                onError: (error) => {
                    notification.error({
                        message: 'Error',
                        description: error.response.data.response_body.message,
                        pauseOnHover: true,
                    })
                },
                onSettled: () => {
                    setIsSubmitButtonDisabled(false)
                    setFormData({
                        name: '',
                        companyName: '',
                        business_email: '',
                        phone: '',
                        message: '',
                    })
                },
            })
            setErrors({})
        } else {
            setErrors(validationErrors)
        }
        setIsSubmitButtonDisabled(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }))
    }
    const handleCheckboxChange = () => {
        setFormData((prevData) => ({
            ...prevData,
            product_demo: !prevData.product_demo,
        }))
    }

    return (
        <div className='p-3 md:p-5 lg:p-10 bg-[#FAFAFA] rounded-[32px]'>
            <h3 className='text-3xl font-bold mb-4 text-brandText'>{t('contactUs:form_header')}</h3>
            <form onSubmit={handleFormSubmit} className='flex flex-col gap-4'>
                <div className='grid grid-cols-2 gap-4 '>
                    <div>
                        <p className='text-brandGray3 mb-2'>
                            {t('common:your_name')}
                            <span className='text-red-500'>*</span>
                        </p>
                        <input
                            type='text'
                            name='name'
                            placeholder={t('common:name_placeholder')}
                            className='border border-gray-300 p-2 rounded-lg text-gray-500 w-full'
                            value={formData.name}
                            onChange={handleChange}
                        />
                        {errors.name && <p className='text-red-500 text-sm'>{errors.name}</p>}
                    </div>
                    <div>
                        <p className='text-brandGray3 mb-2'>
                            {t('common:your_email')}
                            <span className='text-red-500'>*</span>
                        </p>
                        <input
                            type='email'
                            name='business_email'
                            placeholder={t('common:email_placeholder')}
                            className='border border-gray-300 p-2 rounded-lg text-gray-500 w-full'
                            value={formData.business_email}
                            onChange={handleChange}
                        />
                        {errors.business_email && <p className='text-red-500 text-sm'>{errors.business_email}</p>}
                    </div>
                </div>
                <div>
                    <p className='text-brandGray3 mb-2'>{t('common:message')}</p>
                    <textarea
                        name='message'
                        placeholder={t('contactUs:message_placeholder')}
                        className='border border-gray-300 p-2 rounded-lg w-full h-32  text-gray-500'
                        value={formData.message}
                        onChange={handleChange}
                    />
                </div>
                <div className='flex gap-2'>
                    <input type='checkbox' checked={formData.product_demo} onClick={handleCheckboxChange} />
                    <p className='text-brandGray3'>{t('contactUs:checkbox_text')}</p>
                </div>
                <p className='text-brandSecondaryTextColor text-sm '>{t('common:form_consent')}</p>
                <div className='text-center'>
                    <button disabled={isSubmitButtonDisabled} type='submit' className='app-primary-btn w-full py-3'>
                        {t('contactUs:send_message')}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ContactUsForm
