import React, { useState } from 'react'
import { Layout } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { ThbsLogo } from '../../constants/media'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RequestDemoModal from './RequestDemoModal'
import Dropdown from '../Dropdown/Dropdown'

const subscribeLink = process.env.REACT_APP_SUBSCRIPE
const signUpLink = process.env.REACT_APP_SIGN_UP

const { Content } = Layout

const Header = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [requestDemoModalOpen, setRequestDemoModalOpen] = useState(false)
    const items = [
        {
            key: '1',
            label: (
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://docs.torryharrismarketplace.com'
                    className='whitespace-nowrap px-2 text-brandText hover:text-brandText'>
                    {t('common:help_documents')}
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://twitter.com/torryharrisdp'
                    className='whitespace-nowrap px-2 text-brandText hover:text-brandText'>
                    {t('common:press_coverage')}
                </a>
            ),
        },
    ]

    const handleClick = (destination) => {
        if (location.pathname !== '/') {
            navigate(`/?scrollTo=${destination}`)
        } else {
            const element = document.getElementById(destination)
            if (element) {
                const offset = 97 // Adjust this value to your desired offset
                const elementPosition = element.getBoundingClientRect().top
                const offsetPosition = elementPosition + window.scrollY - offset

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                })
            }
        }
    }
    const handleExternalLinkClick = (address) => {
        window.open(address, '_blank')
        closeDrawer()
    }

    const showDrawer = () => {
        setVisible(true)
    }

    const closeDrawer = () => {
        setVisible(false)
    }

    return (
        <header className='sticky w-full top-0 z-30 bg-brandWhite shadow-headerShadow'>
            <Content>
                <div className='flex items-center justify-between py-3 px-4  bg-brandWhite'>
                    <div
                        onClick={() => {
                            navigate('/')
                        }}
                        className='cursor-pointer'>
                        <img src={ThbsLogo} className='w-[120px] md:w-[165px]' alt='ThbsLogo' />
                    </div>
                    {/* <div className='flex md:hidden items-center'>
                        <MenuOutlined onClick={showDrawer} className='text-2xl' />
                    </div> */}
                    <div className='flex gap-9'>
                        <p
                            className={`text-brandText font-normal text-base cursor-pointer`}
                            onClick={() => {
                                handleClick('features')
                            }}>
                            {t('common:features')}
                        </p>
                        <Dropdown items={items}>
                            <div className='flex  gap-1 place-items-center justify-center '>
                                <p className='text-brandText font-normal text-base'>{t('common:resources')} </p>
                                <DownOutlined />
                            </div>
                        </Dropdown>
                        <p
                            className={`text-brandText font-normal text-base cursor-pointer`}
                            onClick={() => {
                                handleClick('price')
                            }}>
                            {t('common:price')}
                        </p>
                        <p
                            className={`${location.pathname === '/contact-us' ? 'text-primaryColor' : 'text-brandText'} cursor-pointer font-normal text-base `}
                            onClick={() => {
                                navigate('/contact-us')
                            }}>
                            {t('contactUs:contact_us')}
                        </p>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <p
                            className='app-text-btn'
                            onClick={() => {
                                handleExternalLinkClick(subscribeLink)
                            }}>
                            {t('common:login')}
                        </p>
                        <button
                            className='app-secondary-btn'
                            onClick={() => {
                                setRequestDemoModalOpen(true)
                            }}>
                            {t('common:request_a_demo')}
                        </button>
                        <button
                            className='app-primary-btn'
                            onClick={() => {
                                handleExternalLinkClick(signUpLink)
                            }}>
                            {t('common:get_started')}
                        </button>
                    </div>
                </div>
                {/* <Drawer
                    title={<span className='text-gray-500'>Menu</span>}
                    placement='right'
                    onClose={closeDrawer}
                    visible={visible}
                    width={'200'}
                    className='!bg-brandBlack'>
                    <Text
                        className='text-gray-500  cursor-pointer !font-bold block mb-3'
                        onClick={handleExternalLinkClick}>
                        Login
                    </Text>
                    <Text
                        onClick={() => {
                            navigate('/contact-us')
                            closeDrawer()
                        }}
                        className='text-gray-500 cursor-pointer !font-bold block'>
                        Contact us
                    </Text>
                </Drawer> */}
            </Content>
            {requestDemoModalOpen ? (
                <RequestDemoModal
                    requestDemoModalOpen={requestDemoModalOpen}
                    setRequestDemoModalOpen={setRequestDemoModalOpen}
                />
            ) : null}
        </header>
    )
}

export default Header
