import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const CancellationAndRefundPolicy = () => {
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='mx-3 sm:mx-auto my-6 sm:max-w-[600px] md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-2xl 2xl:px-40'>
            <h1 className='text-4xl font-bold my-3 text-brandText'>{t('cancellation:cancellation_title')}</h1>
            <h2 className='text-3xl font-semibold mt-6 text-brandSecondaryTextColor'>
                {t('cancellation:cancellation_by_user')}
            </h2>
            <p className='mt-2 font-normal text-[16px] text-brandSecondaryTextColor mb-4'>
                {t('cancellation:best_possible_servie')}
            </p>
            <p className='font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cancellation:any_time_subscription')}{' '}
                <a
                    href='mailto:customer_support@torryharrismarketplace.com'
                    className='text-primaryColor font-semibold underline'>
                    {t('cancellation:support_mail')}
                </a>
            </p>
            <p className='text-3xl font-semibold mt-6 text-brandSecondaryTextColor'>
                {t('cancellation:cancellation_trial')}
            </p>
            <p className='mt-2 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cancellation:cancellation_trial_desc')}
            </p>
            <p className='text-3xl font-semibold mt-6 text-brandSecondaryTextColor'>
                {t('cancellation:cancellation_post_title')}
            </p>
            <p className='mt-2 font-normal text-[16px] text-brandSecondaryTextColor'>
                {' '}
                {t('cancellation:cancellation_post_desc')}
            </p>
            <p className='mt-7 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cancellation:using_the_cancellation')}
            </p>
            <p className='mt-7 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cancellation:effect_immediately')}
            </p>
            <h2 className='text-3xl font-semibold mt-6 text-brandSecondaryTextColor'>
                {t('cancellation:cancel_thm_title')}
            </h2>
            <p className=' mt-2 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cancellation:cancel_thm_desc')}
            </p>
            <h2 className='text-3xl font-semibold mt-6 text-brandSecondaryTextColor'>
                {t('cancellation:no_refund_on')}
            </h2>
            <p className='mt-2 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cancellation:note_that_no_refunds')}
            </p>
            <p className='mt-6 font-normal text-[16px] text-brandSecondaryTextColor'>
                {t('cancellation:not_eligible_for_refund')}
            </p>
            <h2 className='text-3xl font-semibold mt-6 text-brandSecondaryTextColor my-3'>
                {t('cancellation:chargebacks_title')}
            </h2>
            <p className=' mt-2 font-normal text-[16px] text-brandSecondaryTextColor mb-4'>
                {t('cancellation:chargebacks_desc')}
            </p>
            <p className='mt-2 font-normal text-[16px] text-brandSecondaryTextColor mb-4'>
                {t('cancellation:chargebacks_performed')}{' '}
            </p>
            <p className='mt-2 font-normal text-[16px] text-brandSecondaryTextColor leading-6  mb-4'>
                {t('cancellation:using_credit_card')}
            </p>
            <div className='ml-4 font-normal text-[16px] text-brandSecondaryTextColor'>
                <ul className='pl-8 list-disc'>
                    <li>{t('cancellation:payment_method')}</li>
                    <li>{t('cancellation:payment_proof')} </li>
                    <li>{t('cancellation:card_digitals')} </li>
                    <li>{t('cancellation:Chargeback_fee')}</li>
                </ul>
            </div>
            <p className=' mt-6 font-normal text-[16px] text-brandSecondaryTextColor mb-4'>
                {t('cancellation:criminal_fraud_chargebacks')}
            </p>
        </div>
    )
}

export default CancellationAndRefundPolicy
