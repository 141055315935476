import React from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { getImagePath } from '../../util'

const { Text, Title } = Typography

const journeyImagePath = getImagePath('journey')

const Journey = () => {
    const { t } = useTranslation()

    return (
        <section className='bg-white lg:my-16 my-10 p-7'>
            <div className='flex flex-col items-center justify-center text-center'>
                <Title className='font-bold text-[40px] text-brandBlack'>{t('home:chat_your')}</Title>
            </div>
            <div className='flex flex-col items-center justify-center text-center'>
                <Text className='font-normal text-[18px] text-brandSecondaryTextColor'>{t('home:sell_anything')}</Text>
            </div>
            <div className='mt-10 flex justify-center items-center'>
                <img src={journeyImagePath} className='w-full lg:w-[1236px] lg:h-[352px]' alt='journeyImage' />
            </div>
        </section>
    )
}

export default Journey
