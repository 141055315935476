const YoutubeOutlined = () => {
    return (
        <svg width='21' height='15' viewBox='0 0 21 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M8.38623 10.0681L13.5762 7.06812L8.38623 4.06812V10.0681ZM19.9462 2.23812C20.0762 2.70812 20.1662 3.33811 20.2262 4.13811C20.2962 4.93812 20.3262 5.62812 20.3262 6.22812L20.3862 7.06812C20.3862 9.25812 20.2262 10.8681 19.9462 11.8981C19.6962 12.7981 19.1162 13.3781 18.2162 13.6281C17.7462 13.7581 16.8862 13.8481 15.5662 13.9081C14.2662 13.9781 13.0762 14.0081 11.9762 14.0081L10.3862 14.0681C6.19623 14.0681 3.58623 13.9081 2.55623 13.6281C1.65623 13.3781 1.07623 12.7981 0.826231 11.8981C0.696231 11.4281 0.606231 10.7981 0.546231 9.99812C0.476231 9.19812 0.44623 8.50812 0.44623 7.90812L0.38623 7.06812C0.38623 4.87812 0.546231 3.26812 0.826231 2.23812C1.07623 1.33812 1.65623 0.758115 2.55623 0.508115C3.02623 0.378115 3.88623 0.288115 5.20623 0.228115C6.50623 0.158115 7.69623 0.128115 8.79623 0.128115L10.3862 0.0681152C14.5762 0.0681152 17.1862 0.228115 18.2162 0.508115C19.1162 0.758115 19.6962 1.33812 19.9462 2.23812Z'
                fill='white'
            />
        </svg>
    )
}

export default YoutubeOutlined
