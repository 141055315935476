import React from 'react'
import Banner from '../../components/banner/Banner'
import Carousel from '../../components/carousel/Carousel'
import PriceDetails from '../../components/priceDetails/PriceDetails'
import BusinessContext from '../../components/businessContextSection/BusinessContext'
import Subcripe from '../../components/subcripe/Subcripe'
import Journey from '../../components/journey/Journey'
import ThbsOperation from '../../components/operation/ThbsOperation'

const LandingPage = () => {
    return (
        <>
            <div>
                <Banner />
            </div>
            <div id='features'>
                <Journey />
            </div>
            <div>
                <ThbsOperation />
            </div>
            <div id='price'>
                <PriceDetails />
            </div>
            {/* <div>
                <BusinessContext />
            </div>
            <div>
                <Subcripe />
            </div> */}
            <Carousel />
        </>
    )
}

export default LandingPage
