import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollingComponent = () => {
    const location = useLocation()
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const scrollTo = queryParams.get('scrollTo')

        if (scrollTo) {
            const sectionElement = document.getElementById(scrollTo)
            if (sectionElement) {
                const offset = 97 // Adjust this value to your desired offset
                const elementPosition = sectionElement.getBoundingClientRect().top
                const offsetPosition = elementPosition + window.scrollY - offset

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                })
            } else {
                console.error(`Section with ID ${scrollTo} not found.`)
            }
        }
    }, [location])

    return null // This component doesn't render anything
}

export default ScrollingComponent
