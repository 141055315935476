const ThreadOutlined = () => {
    return (
        <svg width='18' height='21' viewBox='0 0 18 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M13.3995 9.33773C13.3134 9.29644 13.2259 9.2567 13.1372 9.21865C12.9829 6.37418 11.4286 4.74572 8.81879 4.72905C8.80696 4.72898 8.7952 4.72898 8.78338 4.72898C7.22238 4.72898 5.92412 5.39529 5.12505 6.60777L6.56036 7.59237C7.1573 6.68669 8.09413 6.49362 8.78407 6.49362C8.79204 6.49362 8.80004 6.49362 8.80792 6.49369C9.66723 6.49917 10.3157 6.74901 10.7354 7.23624C11.0408 7.59096 11.245 8.08114 11.3462 8.69976C10.5843 8.57027 9.76036 8.53046 8.87953 8.58096C6.39824 8.72389 4.80308 10.171 4.91021 12.1819C4.96458 13.2019 5.47272 14.0794 6.34098 14.6526C7.07509 15.1372 8.02057 15.3742 9.0032 15.3205C10.3009 15.2494 11.3189 14.7543 12.0291 13.8489C12.5685 13.1614 12.9096 12.2705 13.0603 11.1479C13.6787 11.5211 14.137 12.0123 14.3902 12.6027C14.8206 13.6063 14.8457 15.2556 13.4999 16.6002C12.3209 17.7781 10.9036 18.2877 8.76166 18.3034C6.38569 18.2858 4.58878 17.5238 3.42047 16.0386C2.32645 14.6479 1.76106 12.6392 1.73996 10.0681C1.76106 7.49705 2.32645 5.48828 3.42047 4.09756C4.58878 2.61238 6.38566 1.85041 8.76163 1.83275C11.1548 1.85054 12.9831 2.61618 14.1961 4.10853C14.7909 4.84036 15.2393 5.7607 15.5349 6.83377L17.2169 6.38501C16.8586 5.06418 16.2947 3.926 15.5274 2.98207C13.9723 1.06881 11.698 0.0884432 8.7675 0.0681152H8.75577C5.83125 0.0883725 3.58235 1.07247 2.07154 2.99304C0.727119 4.70212 0.0336289 7.08017 0.0103268 10.0611L0.0102539 10.0681L0.0103268 10.0751C0.0336289 13.056 0.727119 15.4342 2.07154 17.1432C3.58235 19.0637 5.83125 20.0479 8.75577 20.0681H8.7675C11.3675 20.0501 13.2003 19.3694 14.71 17.8609C16.6854 15.8875 16.6259 13.4138 15.9748 11.8953C15.5078 10.8063 14.6172 9.92189 13.3995 9.33773ZM8.91031 13.5584C7.82281 13.6197 6.693 13.1316 6.6373 12.086C6.596 11.3108 7.18898 10.4458 8.97705 10.3428C9.18182 10.331 9.38276 10.3252 9.58015 10.3252C10.2296 10.3252 10.8372 10.3883 11.3896 10.5091C11.1836 13.0822 9.97505 13.5 8.91031 13.5584Z'
                fill='white'
            />
        </svg>
    )
}

export default ThreadOutlined
